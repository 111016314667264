<template>
  <div>
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Ingresar</h3>
        <p class="text-muted font-weight-semi-bold">
          Ingres su email y la contraseña
        </p>
      </div>
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div role="alert" class="alert alert-info">
          <div class="alert-text">
            Las <strong>credenciales</strong> deben ser las misma de la cuenta
            en <strong>Chameleon</strong>.
          </div>
        </div>

        <b-alert :show="this.load" variant="danger" dismissible>
          {{ this.errors }}
        </b-alert>

        <b-form-group
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email es requirido y debe ser un email valido.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Contraseña es requerido.
          </b-form-invalid-feedback>
        </b-form-group>
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Ingresar
          </button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
<script>
import { LOGIN } from "@/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      },
      errors: "",
      load: false
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(LOGIN, { email, password })
        .then(response => {
          if (response) {
            this.setActivity(response.user.id);
            this.$socket.connect();
            this.$socket.emit("LOGIN_APP");
            // is admin?
            if (response.user.attributes.rol_id == 4) {
              this.$router.push({ name: "admin" });
            } else {
              this.$router.push({ name: "dashboard" });
            }
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          }
        })
        .catch(e => {
          this.errors = e.response.data.error;
          this.load = true;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
    setActivity(userId) {
      this.$store
        .dispatch("activity", {
          user_id: userId
        })
        .then(() => {
          console.log(true);
        })
        .catch(e => {
          console.log(e.response);
        });
    }
  }
};
</script>
